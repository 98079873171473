<template>
    <div class="vue-contact">
        <div class="row" v-if="!success">
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <input type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.name }" id="input-name" placeholder="Namn" v-model="name">
                    <label for="input-name">Namn</label>
                    <span v-if="errors.name" class="invalid-feedback" role="alert">
                        {{ errors.name }}
                    </span>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <input type="email" class="form-control" v-bind:class="{ 'is-invalid': errors.email }" id="input-email" placeholder="E-post" v-model="email">
                    <label for="input-email">E-post</label>
                    <span v-if="errors.email" class="invalid-feedback" role="alert">
                        {{ errors.email }}
                    </span>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <textarea class="form-control h-auto" v-bind:class="{ 'is-invalid': errors.message }" id="input-message" placeholder="Meddelande" rows="6" v-model="message"></textarea>
                    <label for="input-message">Meddelande</label>
                <span v-if="errors.message" class="invalid-feedback" role="alert">
                    {{ errors.message }}
                </span>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-primary" v-on:click="submit">
                    Skicka meddelande
                </button>
                <div v-if="errors.unknown" class="text-danger mt-3">
                    Det gick tyvärr inte att skicka meddelandet. Kontrollera att alla fält är korrekt ifyllda och försök igen.
                </div>
            </div>
        </div>
        <div class="row" v-if="success">
            <div class="col-12 text-center my-5">
                <h3 class="h4 text-success">
                    Tack för ditt meddelande!
                </h3>
                <p class="lead mb-0">
                    Vi har tagit emot ditt meddelande och vi hör av oss inom kort!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                name: null,
                email: null,
                message: null,
                success: false,
                errors: {},
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            submit: function () {
                this.errors = {};

                let data = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
                };

                axios.post('/api/v1/contact/message', data)
                    .then(response => {
                        this.success = (response.data.success) ? true : false;
                    })
                    .catch(e => {
                        if (e.response.data.errors) {
                            this.errors = e.response.data.errors;
                        } else {
                            this.errors = {
                                unknown: true
                            };
                        }
                    })
                    .then(() => {
                        //
                    });
            }
        }
    }
</script>
