<template>
    <div class="vue-newsletter">
        <div v-if="!success" class="d-flex w-100 gap-2">
            <label for="newsletter-signup" class="visually-hidden">
                E-postadress
            </label>
            <input type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.email }" id="newsletter-signup" placeholder="E-postadress" v-model="email">
            <button class="btn btn-primary" type="button" v-on:click="submit">
                Prenumerera
            </button>
        </div>
        <div v-if="success" class="d-flex w-100 gap-2" role="alert">
            <input type="text" class="form-control" placeholder="Tack, du är nu registrerad!" disabled>
            <button class="btn btn-primary disabled" type="button" disabled>
                Prenumerera
            </button>
        </div>
        <span v-if="errors.email" class="text-danger" role="alert">
            {{ errors.email }}
        </span>
        <span v-if="errors.unknown" class="text-danger" role="alert">
            Okänt fel, det gick tyvärr inte att skicka anmälan.
        </span>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                email: null,
                success: false,
                errors: {},
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            submit: function () {
                this.errors = {};

                let data = {
                    email: this.email,
                };

                axios.post('/api/v1/newsletter/subscribe', data)
                    .then(response => {
                        this.success = (response.data.success) ? true : false;
                    })
                    .catch(e => {
                        if (e.response.data.errors) {
                            this.errors = e.response.data.errors;
                        } else {
                            this.errors = {
                                unknown: true
                            };
                        }
                    })
                    .then(() => {
                        //
                    });
            }
        }
    }
</script>
