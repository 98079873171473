<template>
    <div class="vue-klarna" id="vue-klarna">
    </div>
</template>

<script>
    export default {
        props: ['html'],
        data: function () {
            return {
                snippet: null,
            }
        },
        mounted() {
            this.getCheckout();
        },
        methods: {
            getCheckout: function () {
                var checkoutContainer = document.getElementById('vue-klarna');
                checkoutContainer.innerHTML = this.html;

                var scriptsTags = checkoutContainer.getElementsByTagName('script');

                for (var i = 0; i < scriptsTags.length; i++) {
                    var parentNode = scriptsTags[i].parentNode
                    var newScriptTag = document.createElement('script')

                    newScriptTag.type = 'text/javascript'
                    newScriptTag.text = scriptsTags[i].text
                    parentNode.removeChild(scriptsTags[i])
                    parentNode.appendChild(newScriptTag)
                }
            }
        }
    }
</script>
